@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

/* Boostrap customization */

	// Body
	$body-bg: #f8fafc;

	// Typography
	$font-family-sans-serif: 'Open Sans', sans-serif;
	$font-size-base: 0.9rem;
	$line-height-base: 1.6;

	// Colors
	$blue: #00A7DF;
	$indigo: #6574cd;
	$purple: #9561e2;
	$pink: #f66d9b;
	// $red: #e3342f;
	$red: #ff5550;
	$orange: #f6993f;
	$yellow: #ffed4a;
	$green: #38c172;
	$teal: #4dc0b5;
	$cyan: #6cb2eb;

	// Import bootstrap
	@import '~bootstrap/scss/bootstrap.scss';

/* Custom colors */
:root {
	--bs-blue-light: #00a7df9c;
	--bs-indigo-rgb: 101, 116, 205;
	--bs-orange-rgb : 246, 153, 63;
}

@import 'custom.scss';