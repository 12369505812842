/**
 * Generals
 */
body, 
html {
	height: 100%;
	font-size: 12px;
}

input,
textarea,
select {
	&:not([type=button]) {
		font-size: 11px !important;
	}

	&[readonly] {
		background: #e9ecef;
	}
}

label {
	font-size: 9.5px;
	color: var(--bs-primary);
}

.form-control {
	border: 0;
}

#root,
#main-container {
	height: 100%;
}

.sbold {
	font-weight: 500 !important;
}

.btn-unstyled {
	background: transparent;
	border: 0;
	padding: 0;
	margin: 0;
}

.cursor-pointer {
	cursor: pointer;
}

input[type=number].no-arrows {
	/* Chrome, Safari, Edge, Opera */
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
	  -webkit-appearance: none;
	  margin: 0;
	}

	/* Firefox */
	& {
	  -moz-appearance: textfield;
	}
}

.btn-light {
	border: 1px solid rgb(206, 212, 218);

	&:hover {
		border-color: rgb(183 187 191) !important;
	}
}

.btn-light2 {
	background: rgb(206, 212, 218, 0.8);
}

.btn-orange {
	background: var(--bs-orange);
}

.btn-outline-green {
	border-color: var(--bs-green);
	color: var(--bs-green);

	&:hover {
		background: var(--bs-green);
	}
}

.btn-outline-orange {
	border-color: var(--bs-orange);
	color: var(--bs-orange);

	&:hover {
		background: var(--bs-orange);
	}
}

.form-control {
	font-weight: 600;
}

.input-group {

	& > .custom-input,
	& > .custom-select,
	& > .empowered-selector {
		position: relative;
		flex: 1 1 auto;
		width: 1%;
		min-width: 0;
	}

	.input-group-append > .btn-sm {
		font-size: 11px; // Fix por que el tamaño general se ha cambiado
		height: 100%; // Fix por que ahora los custom-inputs lo necesitan para que no se descuadre
		display: inline-flex;
		justify-content: center;
		align-items: center;
	}
	
	.input-group-text {
		border: 0;
	}
}

.card {

	.card-footer {
		z-index: 2;
	}
}

/**
 * Static cols
 *
 */
@for $i from 1 through 400 {
	.col-static-#{$i} {
		width: #{$i}px;
		min-width: #{$i}px;
		max-width: #{$i}px;
	}
}



/**
 *	Colors
 */
.bg-indigo {
	background: var(--bs-indigo);
}

.text-indigo {
	color: var(--bs-indigo);
}

.bg-pink {
	background: var(--bs-pink);
}

.text-pink {
	color: var(--bs-pink);
}

.bg-orange {
	background: var(--bs-orange) !important;
}

.text-orange {
	color: var(--bs-orange);
}



/**
 * Tables
 */
.table-responsive-carded {
	border-radius: 5px;
}

.table-carded {
	margin-bottom: 0;
	background: white;
}

.table {

	& > :not(:first-child) {
		border-top-color: var(--bs-blue-light);
	}

	.btn-table {
		transition: all .2s ease-in-out;

		&:hover {
			display: inline-block;
			transform: scale(1.2);
		}
	}
}

.table-sortable {

	thead {

		tr {

			th {
				font-weight: 600;

				&.sortable {
					cursor: pointer;
					white-space: nowrap;

					&.active {
						color: var(--bs-blue);

						&::after {
							display: inline-block;
							font-size: inherit;
							line-height: inherit;
							margin-left: 5px;
						}

						&.asc {
							&::after {
								content: '↑';
							}
						}

						&.desc {
							&::after {
								content: '↓';
							}
						}
					}
				}	
			}
		}
	}

	tbody {

		tr {

			&.details {
				&:hover {

					* {
						--bs-table-accent-bg: inherit !important; // Prevent hover background for tablehover if tr is details container
					}
				}
			}

			&.has-details {
				cursor: pointer;
			}
		}
	}

}


